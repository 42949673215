<template>
  <div @click="checkContext">
    <div v-show="gltfLoaded" ref="container"></div>
    <div v-show="!gltfLoaded" class="loading">
      <video src="loading.mp4" muted loop autoPlay playsInline></video>
    </div>
    <div v-show="gltfLoaded && showPopup" class="information-popup-container">
      <div
        class="information-popup"
        @click="
          showPopup = false;
          showHideButton = false;
        "
      >
        <div class="information-popup__body">
          <img src="infopopup.svg" alt="" />
        </div>
        <div class="information-popup__footer">
          <div
            @click="
              showPopup = false;
              Tone.start();
            "
          >
            CLOSE
          </div>
          <div v-if="showHideButton" @click="showPopup = !showPopup">
            HIDE FOR TODAY
          </div>
        </div>
      </div>
    </div>
    <div class="record-indicator" v-show="isRecording"></div>

    <div class="controll-info">
      <span class="target-title"> {{ currentControll }} </span>
    </div>
    <transition name="fade">
      <div class="eq-wheel-range" id="eq-high" v-show="isEqHigh">
        <div class="title">
          EQ<br />
          <span class="type">HI</span>
        </div>
        <div class="value">
          <div class="center-line"></div>
          <input type="range" min="0" max=".32" step="0.01" v-model="eqHigh" />
        </div>
        <div class="exit" @click="isEqHigh = false">
          <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="38.1px"
            height="38.1px"
            viewBox="0 0 38.1 38.1"
            style="enable-background: new 0 0 38.1 38.1"
            xml:space="preserve"
          >
            <g>
              <line class="exit-line" x1="35.7" y1="2.5" x2="2.5" y2="35.7" />
              <line class="exit-line" x1="2.5" y1="2.5" x2="35.7" y2="35.7" />
            </g>
          </svg>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div class="eq-wheel-range" id="eq-mid" v-show="isEqMid">
        <div class="title">
          EQ<br />

          <span class="type">MID</span>
        </div>
        <div class="value">
          <div class="center-line"></div>
          <input type="range" min="0" max="0.32" step="0.01" v-model="eqMid" />
        </div>
        <div class="exit" @click="isEqMid = false">
          <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="38.1px"
            height="38.1px"
            viewBox="0 0 38.1 38.1"
            style="enable-background: new 0 0 38.1 38.1"
            xml:space="preserve"
          >
            <g>
              <line class="exit-line" x1="35.7" y1="2.5" x2="2.5" y2="35.7" />
              <line class="exit-line" x1="2.5" y1="2.5" x2="35.7" y2="35.7" />
            </g>
          </svg>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div class="eq-wheel-range" id="eq-low" v-show="isEqLow">
        <div class="title">
          EQ<br />
          <span class="type">LOW</span>
        </div>
        <div class="value">
          <div class="center-line"></div>
          <input type="range" min="0" max="0.32" step="0.01" v-model="eqLow" />
        </div>
        <div class="exit" @click="isEqLow = false">
          <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="38.1px"
            height="38.1px"
            viewBox="0 0 38.1 38.1"
            style="enable-background: new 0 0 38.1 38.1"
            xml:space="preserve"
          >
            <g>
              <line class="exit-line" x1="35.7" y1="2.5" x2="2.5" y2="35.7" />
              <line class="exit-line" x1="2.5" y1="2.5" x2="35.7" y2="35.7" />
            </g>
          </svg>
        </div>
      </div>
    </transition>
    <div v-show="gltfLoaded" class="header">
      <template v-if="!recorded">
        <div class="record" @click="startRecording" v-show="!isRecording">
          RECORD
        </div>
        <div
          class="record recording"
          @click="stopRecording"
          v-show="isRecording"
        >
          STOP
        </div>
      </template>
      <a
        class="record download"
        download="Whiplash-MY_Remix_Ver.webm"
        :href="download"
        v-else
        @click="
          recorded = false;
          isRecording = false;
        "
        >DOWNLOAD</a
      >
      <div class="title" @click="showPopup = true">WHIPMIXER</div>
      <div class="share" @click="share">SHARE</div>
    </div>
    <transition name="fade">
      <div
        v-show="showSharePopup"
        class="share-popup-container"
        @click="showSharePopup = false"
      >
        <div class="share-popup" @click.stop>
          <div class="share-popup__body">
            <img class="share-url" src="icon/url.svg" @click="shareUrl" />
            <img
              class="share-kakao"
              src="icon/kakao.svg"
              @click="shareKakao"
              alt=""
            />
            <img
              class="share-twitter"
              src="icon/twitter.svg"
              @click="shareTwitter"
              alt=""
            />
          </div>
          <div class="share-popup__footer">
            <div @click="showSharePopup = false">CLOSE</div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div class="out-popup-container" v-show="isOut">
        <div class="exit" @click="isOut = false">
          <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="38.1px"
            height="38.1px"
            viewBox="0 0 38.1 38.1"
            style="enable-background: new 0 0 38.1 38.1"
            xml:space="preserve"
          >
            <g>
              <line class="exit-line" x1="35.7" y1="2.5" x2="2.5" y2="35.7" />
              <line class="exit-line" x1="2.5" y1="2.5" x2="35.7" y2="35.7" />
            </g>
          </svg>
        </div>
        <div
          class="model-video"
          v-if="current_song_index == 1 && isOut == true"
        >
          <video
            src="ae-aespa_moving(S).mp4"
            muted
            autoplay
            playsinline
            loop
          ></video>
        </div>
        <div class="swiper" v-if="current_song_index == 2 && isOut == true">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="i in 13" v-bind:key="i">
              <img
                :src="`slides/2/BEAT_WT_${i.toString().padStart(2, '0')}.jpg`"
                alt=""
              />
            </div>
            <div :class="`swiper-slide video ${iframeRatio}`">
              <div id="player"></div>
            </div>
          </div>
          <div class="swiper-button-prev">
            <img src="icon/slide-prev.svg" alt="" />
          </div>
          <div class="swiper-button-next">
            <img src="icon/slide-next.svg" alt="" />
          </div>
        </div>
        <div class="swiper" v-if="current_song_index == 3 && isOut == true">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="i in 13" v-bind:key="i">
              <img
                :src="`slides/3/BEAT_GS_${i.toString().padStart(2, '0')}.jpg`"
                alt=""
              />
            </div>
            <div :class="`swiper-slide video ${iframeRatio}`">
              <div id="player"></div>
            </div>
          </div>
          <div class="swiper-button-prev">
            <img src="icon/slide-prev.svg" alt="" />
          </div>
          <div class="swiper-button-next">
            <img src="icon/slide-next.svg" alt="" />
          </div>
        </div>
        <div class="swiper" v-if="current_song_index == 4 && isOut == true">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="i in 13" v-bind:key="i">
              <img
                :src="`slides/4/BEAT_KR_${i.toString().padStart(2, '0')}.jpg`"
                alt=""
              />
            </div>
            <div :class="`swiper-slide video ${iframeRatio}`">
              <div id="player"></div>
            </div>
          </div>
          <div class="swiper-button-prev">
            <img src="icon/slide-prev.svg" alt="" />
          </div>
          <div class="swiper-button-next">
            <img src="icon/slide-next.svg" alt="" />
          </div>
        </div>
        <div class="swiper" v-if="current_song_index == 5 && isOut == true">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="i in 13" v-bind:key="i">
              <img
                :src="`slides/5/BEAT_NN_${i.toString().padStart(2, '0')}.jpg`"
                alt=""
              />
            </div>
            <div :class="`swiper-slide video ${iframeRatio}`">
              <div id="player"></div>
            </div>
          </div>
          <div class="swiper-button-prev">
            <img src="icon/slide-prev.svg" alt="" />
          </div>
          <div class="swiper-button-next">
            <img src="icon/slide-next.svg" alt="" />
          </div>
        </div>
        <div class="swiper" v-if="current_song_index == 6 && isOut == true">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="video vertical">
                <div id="player"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper" v-if="current_song_index == 7 && isOut == true">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="i in 7" v-bind:key="i">
              <img
                :src="`slides/7/SPEED_KR_${i.toString().padStart(2, '0')}.jpg`"
                alt=""
              />
            </div>
            <div class="swiper-slide" v-for="i in 7" v-bind:key="i">
              <img
                :src="`slides/7/SPEED_NN_${i.toString().padStart(2, '0')}.jpg`"
                alt=""
              />
            </div>
            <div class="swiper-slide" v-for="i in 7" v-bind:key="i">
              <img
                :src="`slides/7/SPEED_WT_${i.toString().padStart(2, '0')}.jpg`"
                alt=""
              />
            </div>
            <div class="swiper-slide" v-for="i in 7" v-bind:key="i">
              <img
                :src="`slides/7/SPEED_GS_${i.toString().padStart(2, '0')}.jpg`"
                alt=""
              />
            </div>
            <div class="swiper-slide" v-for="i in 2" v-bind:key="i">
              <img
                :src="`slides/7/SPEED_GROUP_${i
                  .toString()
                  .padStart(2, '0')}.jpg`"
                alt=""
              />
            </div>
            <div :class="`swiper-slide video ${iframeRatio}`">
              <div id="player"></div>
            </div>
          </div>
          <div class="swiper-button-prev">
            <img src="icon/slide-prev.svg" alt="" />
          </div>
          <div class="swiper-button-next">
            <img src="icon/slide-next.svg" alt="" />
          </div>
        </div>
        <div class="swiper" v-if="current_song_index == 8 && isOut == true">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="i in 16" v-bind:key="i">
              <img
                :src="`slides/8/Teaser_${i.toString().padStart(2, '0')}.jpg`"
                alt=""
              />
            </div>
            <div :class="`swiper-slide video ${iframeRatio}`">
              <div id="player"></div>
            </div>
          </div>
          <div class="swiper-button-prev">
            <img src="icon/slide-prev.svg" alt="" />
          </div>
          <div class="swiper-button-next">
            <img src="icon/slide-next.svg" alt="" />
          </div>
        </div>
        <div class="swiper" v-if="current_song_index == 9 && isOut == true">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="i in 4" v-bind:key="i">
              <img
                :src="`slides/9/MV_KR_${i.toString().padStart(2, '0')}.jpg`"
                alt=""
              />
            </div>
            <div class="swiper-slide" v-for="i in 4" v-bind:key="i">
              <img
                :src="`slides/9/MV_GS_${i.toString().padStart(2, '0')}.jpg`"
                alt=""
              />
            </div>
            <div class="swiper-slide" v-for="i in 4" v-bind:key="i">
              <img
                :src="`slides/9/MV_NN_${i.toString().padStart(2, '0')}.jpg`"
                alt=""
              />
            </div>
            <div class="swiper-slide" v-for="i in 4" v-bind:key="i">
              <img
                :src="`slides/9/MV_WT_${i.toString().padStart(2, '0')}.jpg`"
                alt=""
              />
            </div>
            <div :class="`swiper-slide video ${iframeRatio}`">
              <div id="player"></div>
            </div>
          </div>
          <div class="swiper-button-prev">
            <img src="icon/slide-prev.svg" alt="" />
          </div>
          <div class="swiper-button-next">
            <img src="icon/slide-next.svg" alt="" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script setup>
/* eslint-disable */
import { onMounted, ref, watch } from "vue";
import * as THREE from "three";
import { gsap } from "gsap";
import * as Tone from "tone";

import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GUI } from "dat.gui"; // dat.GUI import

// import Swiper bundle with all modules installed
import Swiper from "swiper/bundle";
// import styles bundle
import "swiper/css/bundle";

import Typed from "typed.js";

let currentControll = ref(null);
let current_tempo = ref(1);
let showPopup = ref(true);
let bitcrusher = ref(8);
let container = ref(null);
let run = ref(false);
let isMusicChanging = ref(false);
let camera = null,
  renderer = null,
  orbitControls = null,
  myClip = null,
  anim = null,
  myTrack = null,
  mixer = null,
  action = null,
  outMesh = null,
  gltfScene = ref(null),
  screen_texture = null,
  gltfLoaded = ref(false),
  scene = null,
  tempo = ref(0),
  screen_bottom = null,
  screen_top = null,
  screen_bottom_material = null,
  screen_top_material = null,
  modelVideo = ref(null),
  showHideButton = ref(true),
  current_song_index = ref(9),
  songLength = ref(9),
  outPopupSlides = ref(null),
  current_wheel_rad = ref(0);

let isEqHigh = ref(false),
  isEqMid = ref(false),
  isEqLow = ref(false),
  isOut = ref(false);

let outPopup = ref({});

let EqHiAnim = ref(null);
let isRecording = ref(false),
  recorded = ref(false),
  download = ref(null);
let tz = ref(-1.0619417523734165),
  ty = ref(0.1485141416139239);

const clock = new THREE.Clock();

const names = [
  "Start-Start",
  "NEXT",
  "PREVIOUSLY",
  "EQ_TEMPO_Control001",
  "Sound_Color_01",
  "Sound_Color_02",
  "Sound_Color_03",
  "obj78-EQ_조절3",
  "obj77-EQ_조절3",
  "obj77-EQ_조절002",
  "Control_01",
  "Out",
];
const animWithMeshes = {
  "Start-Start": {
    mesh_name: "Start-Start",
    anim_index: 10,
    action() {
      if (Tone.getContext().state !== "running") {
        Tone.start();
      } else {
        run.value = !run.value;
      }
    },
  },
  NEXT: {
    mesh_name: "NEXT",
    anim_index: 4,
    action() {
      if (current_song_index.value <= 1) {
        current_song_index.value = songLength.value;
      } else {
        current_song_index.value--;
      }
    },
  },
  PREVIOUSLY: {
    mesh_name: "PREVIOUSLY",
    anim_index: 5,
    action() {
      if (current_song_index.value >= songLength.value) {
        current_song_index.value = 1;
      } else {
        current_song_index.value++;
      }
    },
  },
  EQ_TEMPO_Control001: {
    mesh_name: "EQ_TEMPO_Control001",
    anim_index: 0,
    animation: null,
  },
  Sound_Color_01: {
    mesh_name: "Sound_Color_01",
    anim_index: 7,
    action() {
      isRadioFilter.value = !isRadioFilter.value;
    },
  },
  Sound_Color_02: {
    mesh_name: "Sound_Color_02",
    anim_index: 8,
    action() {
      isHiBoost.value = !isHiBoost.value;
    },
  },
  Sound_Color_03: {
    mesh_name: "Sound_Color_03",
    anim_index: 9,
    action() {
      isLowBoost.value = !isLowBoost.value;
    },
  },
  "obj78-EQ_조절3": {
    mesh_name: "obj78-EQ_조절3",
    anim_index: 1,
    action() {
      isEqMid.value = false;
      isEqLow.value = false;
      isEqHigh.value = !isEqHigh.value;
    },
  },
  "obj77-EQ_조절3": {
    mesh_name: "obj77-EQ_조절3",
    anim_index: 2,
    action() {
      isEqHigh.value = false;
      isEqLow.value = false;

      isEqMid.value = !isEqMid.value;
    },
  },
  "obj77-EQ_조절002": {
    mesh_name: "obj77-EQ_조절002",
    anim_index: 3,
    action() {
      isEqMid.value = false;
      isEqHigh.value = false;

      isEqLow.value = !isEqLow.value;
    },
  },
  Out: {
    mesh_name: "Out",
    anim_index: 6,
  },
};
let scratching = ref(false);
let clicked_object;
let multiPlayer = null;
const recorder = new Tone.Recorder();

let raycaster_dragging = ref(false);
let controller = ref(null);
let delay = new Tone.FeedbackDelay("5n", 0.03).toDestination();
let reverb = new Tone.Reverb(40).toDestination();
let eqForWheel;
let isWheel = ref(false);
let eqHigh = ref(0.17),
  eqMid = ref(0.17),
  eqLow = ref(0.17);

let eqTrack, eqClip, eqMixer, eqAction;

let iframeRatio = ref(null);

function startRecording() {
  recorder.start();
  isRecording.value = true;
}

async function stopRecording() {
  const recording = await recorder.stop();

  isRecording.value = false;
  recorded.value = true;
  const url = URL.createObjectURL(recording);
  const anchor = document.createElement("a");
  anchor.download = "Whiplash-MY_Remix_Ver.webm";
  download = url;
}

function eqWheelAnimation(meshInfo, updateValue) {
  eqTrack = [anim.tracks[meshInfo.anim_index]];
  eqClip = new THREE.AnimationClip(meshInfo.mesh_name, 1, eqTrack);
  eqMixer = new THREE.AnimationMixer(gltfScene.value);
  eqAction = eqMixer.clipAction(eqClip); // 클립 액션 생성
  eqAction.reset();
  eqAction.paused = true; // 애니메이션 일시 정지 해제
  eqAction.setLoop(THREE.LoopOnce);
  eqAction.time = updateValue;
  eqAction.play(); // 애니메이션 실행
  eqMixer.update(0); // 업데이트 호출
}

let isRadioFilter = ref(false),
  isHiBoost = ref(false),
  isLowBoost = ref(false),
  isEq1 = ref(false),
  isEq2 = ref(false),
  isEq3 = ref(false);

let crusher = new Tone.BitCrusher(bitcrusher.value).toDestination();

let radioFilterEq = new Tone.EQ3({
  lowFrequency: 500, // Low 주파수 경계를 500Hz로 올림
  highFrequency: 1500, // High 주파수 경계를 1500Hz로 낮춤
  low: -Infinity, // 저음역대 (dB로 설정, -값으로 줄이고 +값으로 높임)
  mid: 3, // 중음역대 (dB로 설정)
  high: -Infinity, // 고음역대 (dB로 설정)
}).toDestination();

let hiBoostEq = new Tone.EQ3({
  low: -15, // 저주파를 크게 감쇠 (저음 부분을 많이 줄여서 고음 강조)
  mid: -10, // 중주파도 많이 감쇠 (고주파 강조를 위해 중간 대역을 약하게)
  high: 15, // 고주파를 더 크게 강조 (최대로 고음 대역을 강조)
}).toDestination();

let lowBoostEq = new Tone.EQ3({
  low: 15, // 저주파를 크게 강조 (저음 강조)
  mid: -10, // 중주파 감쇠 (저음 강조를 방해하지 않도록 중간 음역을 줄임)
  high: -15, // 고주파 감쇠 (저주파를 더 두드러지게 하기 위해 고음 감쇠)
}).toDestination();

// let showCurrentControll = ref(false);
// let hideControll;
// let isDragging = false;
// watch(showCurrentControll, (value) => {
//   if (value) {
//     hideControll = setTimeout(() => {
//       showCurrentControll.value = false;
//     }, 3000);
//   }
// });
function shareTwitter() {
  var sendText = "Whiplash (MY Remix Ver.) ";
  var sendUrl = window.location.href; // 전달할 URL
  var encodedUrl = encodeURIComponent(sendUrl);
  var encodedText = encodeURIComponent(sendText);
  var twitterUrl =
    "https://twitter.com/intent/tweet?text=" +
    encodedText +
    "&url=" +
    encodedUrl;
  window.open(twitterUrl, "_blank");
}

function shareUrl() {
  const url = window.location.href; // 현재 페이지의 URL을 가져옵니다.

  if (navigator.share) {
    navigator.share({
      title: "Whiplash (MY Remix Ver.) ",
      text: "[WHIPMIXER] aespa The 5th Mini Album - Whiplash",
      url: url,
    });
  } else {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        alert("URL이 복사되었습니다.");
      })
      .catch((err) => {
        console.error("복사 실패:", err);
      });
  }
}
function toggleObjMoving(value) {
  if (value) {
    gsap.to(gltfScene.value.rotation, {
      x: 0,
      y: 0,
      z: 0,
      duration: 1,
      ease: "power1.inOut",
      onUpdate: () => {
        orbitControls.update(); // 애니메이션 도중 카메라 위치 업데이트
      },
      onComplete: () => {
        orbitControls.update(); // 애니메이션이 완료된 후에도 업데이트
        rotationX = 0;
        rotationY = 0;
        rotationZ = 0;
      },
    });

    gsap.to(camera.position, {
      x: -0.1,
      y: 99,
      z: 14.1,
      duration: 1.5, // 애니메이션 지속 시간
      ease: "power1.inOut",
      onUpdate: () => {
        camera.lookAt(0, 0, 0); // 카메라가 항상 모델을 바라보도록 설정
        orbitControls.update(); // 카메라 이동 중에도 OrbitControls 업데이트
      },
      onComplete: () => {
        rotationX = 0;
        rotationY = 0;
        rotationZ = 0;
      },
    });
    // gsap.to(camera.rotation, {
    //   x: -1.4,
    //   y: 0,
    //   z: -0.01,
    //   duration: 1.5, // 애니메이션 지속 시간
    //   ease: "power1.inOut",
    //   onUpdate: () => {
    //     camera.lookAt(0, 0, 0); // 카메라가 항상 모델을 바라보도록 설정
    //     orbitControls.update(); // 카메라 이동 중에도 OrbitControls 업데이트
    //   },
    // });
    orbitControls.enabled = false;
  } else {
    orbitControls.enabled = true;
  }
}

function toggleButtonLight(value) {
  if (value) {
    gsap.to(outMesh.material, {
      emissiveIntensity: 1,
      duration: 0.3,
      ease: "power1.inOut",
    });
  } else {
    gsap.to(outMesh.material, {
      emissiveIntensity: 0,
      duration: 0.3,
      ease: "power1.inOut",
    });
  }
}

function starting() {
  multiPlayer.player(current_song_index.value).playbackRate *= 1.03;
  if (
    multiPlayer.player(current_song_index.value).playbackRate >=
    current_tempo.value
  ) {
    multiPlayer.player(current_song_index.value).playbackRate =
      current_tempo.value;
    isMusicChanging.value = false;

    return;
  }
  requestAnimationFrame(starting);
}
function stopping() {
  multiPlayer.player(current_song_index.value).playbackRate *= 0.85;
  if (multiPlayer.player(current_song_index.value).playbackRate <= 0.01) {
    multiPlayer.player(current_song_index.value).playbackRate = 0.1;

    multiPlayer.player(1).stop();
    multiPlayer.player(2).stop();
    multiPlayer.player(3).stop();
    multiPlayer.player(4).stop();
    multiPlayer.player(5).stop();
    multiPlayer.player(6).stop();
    multiPlayer.player(7).stop();
    multiPlayer.player(8).stop();
    multiPlayer.player(9).stop();
    isMusicChanging.value = false;
    return;
  }
  requestAnimationFrame(stopping);
}

let showSharePopup = ref(false);
function share() {
  const params = new URLSearchParams();
  params.set("eqLow", eqLow.value);
  params.set("eqMid", eqMid.value);
  params.set("eqHigh", eqHigh.value);
  params.set("current_tempo", current_tempo.value);
  params.set("isRadioFilter", isRadioFilter.value);
  params.set("isHiBoost", isHiBoost.value);
  params.set("isLowBoost", isLowBoost.value);
  params.set("tz", tz.value);
  params.set("ty", ty.value);

  const newUrl = `${window.location.pathname}?${params.toString()}`;

  window.history.pushState({}, "", newUrl);

  showSharePopup.value = true;
}

function shareKakao() {
  // 현재 보고 있는 페이지의 url +  url params  모두 가여조오기
  if (!Kakao.isInitialized()) {
    const thumbImg = encodeURIComponent(
      `${window.location.origin}/thumbnail/thumbnail.png`
    );
    const thumbTitle = "Whiplash (MY Remix Ver.)"; //og타이틀
    const thumbDesc = "[WHIPMIXER] aespa The 5th Mini Album - Whiplash";
    Kakao.init("c049f680cf7c6ce42903a65db44731b8");
    Kakao.Link.sendDefault({
      objectType: "feed",
      content: {
        title: thumbTitle,
        description: thumbDesc,
        imageUrl: thumbImg,
        imageWidth: 800,
        imageHeight: 400,
        link: {
          mobileWebUrl: location.href,
          androidExecutionParams: "test",
        },
      },
      itemContent: {
        profileText: "Whiplash (MY Remix Ver.)",
      },
      buttons: [
        {
          title: "CHECK WHIPMIXER",
          link: {
            mobileWebUrl: location.href,
            webUrl: location.href,
          },
        },
      ],
    });
  }
}

function checkContext() {
  if (Tone.getContext().state !== "running") {
    Tone.start();
  }
}

/*
모든 threejs요소들은 상태변경만 담당하고
watch에서 변경된 상태에 따른 기능을 모두 담당하겠습니다.
*/
// 음악의 재생/정지 시 반응하는 함수
watch(run, (value) => {
  isMusicChanging.value = true;
  if (value) {
    if (Tone.getContext().state !== "running") {
      Tone.start();
    } else {
      multiPlayer.player(current_song_index.value).start();
    }
    starting();
    gsap.to(screen_bottom_material, {
      opacity: 1,
      duration: 0.3,
      ease: "power1.inOut",
    });
    gsap.to(screen_top_material, {
      opacity: 1,
      duration: 0.3,
      ease: "power1.inOut",
    });

    currentControll.value = "Start";
  } else {
    stopping();
    gsap.to(screen_bottom_material, {
      opacity: 0,
      duration: 0.3,
      ease: "power1.inOut",
    });
    gsap.to(screen_top_material, {
      opacity: 0,
      duration: 0.3,
      ease: "power1.inOut",
    });

    eqHigh.value = 0.17;
    eqMid.value = 0.17;
    eqLow.value = 0.17;
    gltfScene.value.getObjectByName("EQ_TEMPO_Control001").position.z =
      -1.0619417523734165;
    gltfScene.value.getObjectByName(
      "EQ_TEMPO_Control001"
    ).position.y = 0.1485141416139239;

    isRadioFilter.value = false;
    isHiBoost.value = false;
    isLowBoost.value = false;
    current_tempo.value = 1;
    setTimeout(function () {
      currentControll.value = "Stop";
    }, 1);

    // main_screen.material = new THREE.MeshStandardMaterial({
    //   color: 0x000000,
    //   roughness: 0.2,
    //   metalness: 1.1,
    //   envMap: scene.environment,
    //   envMapIntensity: 0.9,
    //   emissive: 0x000000,
    //   emissiveIntensity: 0,
    // });
  }

  toggleObjMoving(value);
  toggleButtonLight(value);
});

watch(bitcrusher, (value) => {
  crusher.dispose();
  crusher = new Tone.BitCrusher(value).toDestination();
  multiPlayer.connect(crusher);
});

// Sound Color 01 - Bitcrusher
watch(isRadioFilter, (value) => {
  let target = gltfScene.value.getObjectByName("Sound_Color_01");

  if (value) {
    multiPlayer.connect(radioFilterEq);
    multiPlayer.connect(crusher);
    currentControll.value = "Radio Filter : ON";

    // BUG 비트크러셔바뀔때 색 변경들어갈 자리

    // target.material.roughness = 1;
    // target.material.metalness = 0;
    // target.material.emissive = 0x000000;
    // gsap.to(target.material.color, {
    //   r: 0, // Red component (0 to 1 range)
    //   g: 1, // Green component (0 to 1 range)
    //   b: 0, // Blue component (0 to 1 range)
    //   duration: 0.1, // 애니메이션 시간
    //   onUpdate: () => {
    //     gltfScene.value.getObjectByName(
    //       "Sound_Color_01"
    //     ).material.needsUpdate = true; // 머터리얼 업데이트
    //   },
    // });
  } else {
    multiPlayer.disconnect(radioFilterEq);
    multiPlayer.disconnect(crusher);

    // target.material = new THREE.MeshStandardMaterial({
    //   color: 0x000000,
    //   roughness: 0.2,
    //   metalness: 0.5,
    //   envMap: scene.environment,
    //   envMapIntensity: 0,
    // });

    currentControll.value = "Radio Filter : OFF";
  }
});

// Sound color 02 - Reverb
watch(isHiBoost, (value) => {
  value ? multiPlayer.connect(hiBoostEq) : multiPlayer.disconnect(hiBoostEq);
  value
    ? (currentControll.value = "High Boost : ON")
    : (currentControll.value = "High Boost : OFF");
});

// Sound color 03 - Delay
watch(isLowBoost, (value) => {
  value ? multiPlayer.connect(lowBoostEq) : multiPlayer.disconnect(lowBoostEq);
  value
    ? (currentControll.value = "Low Boost : ON")
    : (currentControll.value = "Low Boost :OFF");
});

watch(current_tempo, (value) => {
  multiPlayer.playbackRate = value;
  currentControll.value = `Temp : ${value.toFixed(2)}`;
});

watch(eqHigh, (value) => {
  eqWheelAnimation(animWithMeshes["obj78-EQ_조절3"], value);
  eqForWheel.high.value = (value - 0.16) * (40 / 0.32);
  currentControll.value = `EQ HI : ${eqForWheel.high.value.toFixed(2)}`;
});

watch(eqMid, (value) => {
  eqWheelAnimation(animWithMeshes["obj77-EQ_조절3"], value);
  eqForWheel.mid.value = (value - 0.16) * (40 / 0.32);
  currentControll.value = `EQ MID : ${eqForWheel.mid.value.toFixed(2)}`;
});

watch(eqLow, (value) => {
  eqWheelAnimation(animWithMeshes["obj77-EQ_조절002"], value);
  eqForWheel.low.value = (value - 0.16) * (40 / 0.32);
  currentControll.value = `EQ LOW : ${eqForWheel.low.value.toFixed(2)}`;
});

watch(current_wheel_rad, (value, newValue) => {
  gltfScene.value.getObjectByName("Wheel_Main").rotation.y = value;
  gltfScene.value.getObjectByName("Wheel01").rotation.y = value;
});

let fadeOut;
let eqwheelToggle;
watch(currentControll, (value) => {
  if (fadeOut) clearTimeout(fadeOut);
  if (value) {
    document.querySelector(".controll-info").style.opacity = 1;
    fadeOut = setTimeout(() => {
      document.querySelector(".controll-info").style.opacity = 0;
    }, 1500);
  }

  if (eqwheelToggle) clearTimeout(eqwheelToggle);
  if (value.includes("EQ")) {
    eqwheelToggle = setTimeout(() => {
      isEqHigh.value = false;
      isEqMid.value = false;
      isEqLow.value = false;
    }, 3000);
  }
});

watch(tz, (value) => {
  gltfScene.value.getObjectByName("EQ_TEMPO_Control001").position.z = value;
});
watch(ty, (value) => {
  gltfScene.value.getObjectByName("EQ_TEMPO_Control001").position.y = value;
});
let typed;
watch(isRecording, (value) => {
  if (value) {
    typed = new Typed(".record-indicator", {
      strings: ["Recording..."],
      typeSpeed: 50,
      loop: true,
      fadeOut: true,
    });
  } else {
    typed.destroy();
  }
});

watch(isOut, (value) => {
  let swiper;
  let videoId;
  switch (current_song_index.value) {
    case 1:
      videoId = `iq-ivLY_9A8`;
      break;
    case 2:
      videoId = `WgekCR5GHs8`;
      break;
    case 3:
      videoId = `DSX67Bo96MQ`;
      break;
    case 4:
      videoId = `WXrhOnw-Qrs`;
      break;
    case 5:
      videoId = `_plo68R61No`;
      break;
    case 6:
      videoId = `MODJZjOUwNA`;
      break;
    case 7:
      videoId = `Y7jLJvwvkaE`;
      break;
    case 8:
      videoId = `GjCNoZF9HxQ`;
      break;
    case 9:
      videoId = `jWQx2f-CErU`;
      break;
    default:
      videoId = null;
      break;
  }

  if (value) {
    setTimeout(() => {
      // YouTube Player 초기화
      let player;
      let playerReady = false;
      window.onYouTubeIframeAPIReady = function () {
        player = new YT.Player("player", {
          videoId, // 예시 YouTube 영상 ID
          events: {
            onReady: onPlayerReady,
          },
          playerVars: {
            playsinline: 1,
          },
        });
      };

      function onPlayerReady(event) {
        playerReady = true;
      }

      // Swiper 초기화
      swiper = new Swiper(".swiper", {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });

      // YouTube API 스크립트가 이미 있는지 확인
      if (!window.YT || !window.YT.Player) {
        var tag = document.createElement("script");
        tag.src = "https://www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      } else {
        onYouTubeIframeAPIReady();
      }

      // 음원멈춤 / 현재시간 저장

      // Swiper 슬라이드 변경 이벤트
      swiper.on("slideChange", function (e) {
        let activeIndex = swiper.activeIndex;
        // 현재 슬라이드 DOM 요소
        let onVideoSlide = swiper.slides[activeIndex].querySelector("iframe");

        if (onVideoSlide) {
          if (playerReady) {
            player.playVideo();
            if (run.value) {
              multiPlayer.player(current_song_index.value).stop();
            }
          }

          onVideoSlide.onload = function () {
            player.playVideo();
            if (run.value) {
              multiPlayer.player(current_song_index.value).stop();
            }
          };
        } else {
          if (playerReady) {
            player.stopVideo();
          }
          if (run.value) {
            if (multiPlayer.player(current_song_index.value).state == "stopped")
              multiPlayer.player(current_song_index.value).start();
          }
        }
      });
    }, 1);
  } else {
    if (run.value) {
      if (multiPlayer.player(current_song_index.value).state == "stopped")
        multiPlayer.player(current_song_index.value).start();
    }
  }
});

watch(current_song_index, (value) => {
  // 이미지를 생성하고, 그 텍스쳐를 planeScreen 에 적용
  screen_texture = new THREE.TextureLoader().load(
    `screen/song-0${value}.png`,
    (texture) => {
      // 텍스처 로드가 완료되면 적용
      screen_bottom_material.map = texture;
      screen_bottom_material.needsUpdate = true;
      multiPlayer.player(1).stop();
      multiPlayer.player(2).stop();
      multiPlayer.player(3).stop();
      multiPlayer.player(4).stop();
      multiPlayer.player(5).stop();
      multiPlayer.player(6).stop();
      multiPlayer.player(7).stop();
      multiPlayer.player(8).stop();
      multiPlayer.player(9).stop();
      multiPlayer.player(value).start();
    }
  );
});
onMounted(() => {
  // url params
  setIframeratio();
  eqForWheel = new Tone.EQ3({
    low: 0, // 저음역대 (dB로 설정, -값으로 줄이고 +값으로 높임)
    mid: 0, // 중음역대 (dB로 설정)
    high: 0, // 고음역대 (dB로 설정)
  }).toDestination();
  multiPlayer = new Tone.Players({
    1: `sounds/Whiplash-MY_Remix_Ver.wav`,
    2: `sounds/UNBEATABLE_BEAT_WINTER.wav`,
    3: `sounds/UNBEATABLE_BEAT_GISELLE.wav`,
    4: `sounds/UNBEATABLE_BEAT_KARINA.wav`,
    5: `sounds/UNBEATABLE_BEAT_NINGNING.wav`,
    6: "sounds/The_5th_Mini_HLM_Mix1.wav",
    7: "sounds/Whiplash_Promotion_Master.wav",
    8: "sounds/Whiplash_Teaser_Mastered.wav",
    9: "sounds/Whiplash.wav",
  }).toDestination();
  // get player from multiplayer

  multiPlayer.player(1).loop = true;
  multiPlayer.player(2).loop = true;
  multiPlayer.player(3).loop = true;
  multiPlayer.player(4).loop = true;
  multiPlayer.player(5).loop = true;
  multiPlayer.player(6).loop = true;
  multiPlayer.player(7).loop = true;
  multiPlayer.player(8).loop = true;
  multiPlayer.player(9).loop = true;
  Tone.loaded().then(() => {
    multiPlayer.player(current_song_index.value).playbackRate = 0.1;
    multiPlayer.connect(eqForWheel);
    multiPlayer.connect(recorder);
  });

  // 모델링 기본 환경만들기
  {
    scene = new THREE.Scene();
    // scene.background = new THREE.Color(0xb8b7b7);
    // scene.background = new THREE.Color(0x000000);
    camera = new THREE.PerspectiveCamera(
      18,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );

    camera.position.set(25, 84, 48);
    camera.rotation.set(-1, 0.252, 0.408);
    camera.lookAt(0, 0, 0);

    // 추가 조명 설정
    const hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 1); // 색상과 강도 설정
    scene.add(hemiLight);

    // 주변 조명 설정
    const ambientLight = new THREE.AmbientLight(0xffffff, 1); // 색상과 강도 설정
    scene.add(ambientLight);

    const dirLight = new THREE.DirectionalLight(0xffffff, 1); // 강도 설정
    dirLight.position.set(0, 200, -100);
    scene.add(dirLight);

    renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
    });
    if (!renderer.capabilities.isWebGL2) {
      renderer.capabilities.getExtension("OES_texture_float") ||
        renderer.capabilities.getExtension("OES_texture_float_linear");
    }

    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 1));
    renderer.setSize(window.innerWidth, window.innerHeight);

    orbitControls = new OrbitControls(camera, renderer.domElement);
    orbitControls.maxDistance = 100;
    orbitControls.minDistance = 80;

    container.value.appendChild(renderer.domElement);

    // const axesHelper = new THREE.AxesHelper(99);
    // scene.add(axesHelper);
  }

  // 환경맵 로드하기
  {
    const rgbeLoader = new RGBELoader();

    rgbeLoader.load(`modeling/environment.hdr`, function (texture) {
      texture.mapping = THREE.EquirectangularReflectionMapping;
      scene.environment = texture; // 환경 맵을 씬에 설정하여 반사와 조명에 영향을 줌
      scene.position.set(0, 0, 0);

      //environment intensity
      scene.environmentIntensity = 0.4;
    });
  }

  // raycaster을 만들어서 마우스로 클릭한 부분의 메쉬 정보를 가져오자.
  {
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();
    let centerX;
    let centerY;

    let isDragging = false;
    let prevAngle = 0;
    let rotation = 0;
    let angularSpeed = 0; // 각속도 추가

    let lastPosition = null;
    let lastTime = null;
    let speed = 0;
    let timeoutId = null;
    const MIN_DIFF = 0.001; // 각도 차이가 너무 작을 때의 임계값

    function calculateAngle(clientX, clientY) {
      const dx = clientX - centerX;
      const dy = clientY - centerY;
      return Math.atan2(dy, dx);
    }

    function getAngleDifference(angle1, angle2) {
      let diff = Math.atan2(
        Math.sin(angle1 - angle2),
        Math.cos(angle1 - angle2)
      );

      // 각도 차이가 너무 작으면 MIN_DIFF 이상으로 보정
      if (Math.abs(diff) < MIN_DIFF) {
        diff = MIN_DIFF * Math.sign(diff); // 각도 차이에 따라 부호 유지
      }

      return -1 * diff;
    }

    // // touchMouseStart
    container.value.addEventListener("pointerdown", onTouchMouseStart);

    function onTouchMouseStart(event) {
      scratching.value = true;
      prevAngle = calculateAngle(event.clientX, event.clientY);
      lastPosition = { x: event.clientX, y: event.clientY };
      lastTime = performance.now();
      raycaster_dragging.value = true;

      if (event.pointerType === "touch") {
        event.preventDefault(); // 터치 입력을 다룸
        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
        handleRaycast();
        return;
      } else if (event.pointerType === "mouse") {
        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
        handleRaycast();
        return;
      }
    }

    function calcJogWheelCenter() {
      let target = gltfScene.value.getObjectByName("Control");

      // 객체의 경계 박스를 계산합니다.
      let boundingBox = new THREE.Box3().setFromObject(target);

      // 경계 박스의 최소와 최대값을 사용해 중앙을 계산합니다.
      let center = boundingBox.getCenter(new THREE.Vector3());

      var pos = new THREE.Vector4(center.x, center.y, center.z, 1.0);

      // 월드 좌표를 카메라 공간으로 변환 (View Matrix 적용)
      pos.applyMatrix4(camera.matrixWorldInverse);

      // 카메라 공간을 클립 공간으로 변환 (Projection Matrix 적용)
      pos.applyMatrix4(camera.projectionMatrix);

      // 동차 좌표를 일반 좌표로 변환
      pos.divideScalar(pos.w);

      // NDC 좌표(-1 ~ 1)를 스크린 좌표로 변환
      var width = renderer.domElement.clientWidth;
      var height = renderer.domElement.clientHeight;

      var x = (pos.x * 0.5 + 0.5) * width;
      var y = (-pos.y * 0.5 + 0.5) * height;

      // 정확한 중심 좌표 반환
      var screenPosition = new THREE.Vector2(x, y);
      return screenPosition;
    }

    // 드래그 (TEMPO, JOG WHEEL)
    {
      const minMouseY = -0.6,
        maxMouseY = -0.24,
        minZ = -3.68, // mouseY가 -0.67일 때의 position.z
        maxZ = 1.61, // mouseY가 -0.24일 때의 position.z
        minY = 0.49, // mouseY가 -0.67일 때의 position.y
        maxY = -0.2; // mouseY가 -0.24일 때의 position.
      let rotation = 0;

      container.value.addEventListener("pointermove", onPointerMove);

      function onPointerMove(event) {
        if (raycaster_dragging.value && isWheel.value && run.value) {
          let jogWheel = calcJogWheelCenter();
          centerX = jogWheel.x;
          centerY = jogWheel.y;
          rotation = current_wheel_rad.value;

          const currentAngle = calculateAngle(event.clientX, event.clientY);
          let angleDiff = getAngleDifference(currentAngle, prevAngle)
            ? getAngleDifference(currentAngle, prevAngle)
            : 0;
          rotation += angleDiff;

          let smoothingFactor = 0.1; // 작은 움직임에 대한 가속도 또는 보정 계수
          angleDiff = angleDiff * smoothingFactor; // 각도 차이를 일정 비율로 부드럽게 적용
          rotation += angleDiff;
          current_wheel_rad.value = rotation;
          prevAngle = currentAngle;

          let currentPosition = { x: event.clientX, y: event.clientY };
          let currentTime = performance.now();

          let deltaX = currentPosition.x - lastPosition.x;
          let deltaY = currentPosition.y - lastPosition.y;
          let distance = Math.hypot(deltaX, deltaY);
          let timeElapsed = (currentTime - lastTime) / 1000; // 초 단위로 변환

          speed = distance / timeElapsed; // px/s 단위 속도 계산
          multiPlayer.player(current_song_index.value).playbackRate =
            speed.toFixed(2) / 300;
          lastPosition = currentPosition;
          lastTime = currentTime;

          // 마우스가 멈추면 속도를 0으로 설정
          clearTimeout(timeoutId);
          timeoutId = setTimeout(function () {
            multiPlayer.player(current_song_index.value).playbackRate = 0;
          }, 100);
        }

        if (raycaster_dragging.value && run.value) {
          mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
          mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

          // mouseY 값을 최대와 최소 사이에서 현재 0~1 사이의 값으로 변환
          if (mouse.y <= maxMouseY && mouse.y >= minMouseY) {
            // 선형 보간 (lerp) 사용하여 position.z 값 계산
            const newZ =
              minZ +
              (maxZ - minZ) * ((mouse.y - maxMouseY) / (minMouseY - maxMouseY));

            // 선형 보간 (lerp) 사용하여 position.y 값 계산
            const newY =
              minY +
              (maxY - minY) * ((mouse.y - maxMouseY) / (minMouseY - maxMouseY));

            // clicked_object가 존재하는지 확인한 후 position 업데이트
            if (clicked_object) {
              if (clicked_object.name == "EQ_TEMPO_Control001") {
                clicked_object.position.z = newZ;
                clicked_object.position.y = newY;

                tz.value = newZ;
                ty.value = newY;

                let calced_value =
                  (newY - -0.19) * ((1.35 - 0.65) / (0.487 - -0.19)) + 0.65 < 0
                    ? 0
                    : (newY - -0.19) * ((1.35 - 0.65) / (0.487 - -0.19)) + 0.65;
                current_tempo.value = calced_value;
              }
            }
          }
        }
      }

      container.value.addEventListener("touchend", onTouchMouseEnd);
      container.value.addEventListener("mouseup", onTouchMouseEnd);
      function onTouchMouseEnd(event) {
        scratching.value = false;
        raycaster_dragging.value = false;
        isWheel.value = false;
        setTimeout(function () {
          multiPlayer.player(current_song_index.value).playbackRate =
            current_tempo.value;
          multiPlayer.reverse = false;
        }, 150);
      }
    }

    // Raycast 관련된 처리는 다 이쪽에서
    function handleRaycast() {
      raycaster.setFromCamera(mouse, camera);
      const intersects = raycaster.intersectObjects(scene.children, true);
      if (intersects.length > 0) {
        clicked_object = intersects[0].object;
        const name = clicked_object.name;
        const animWithMesh = animWithMeshes[name];

        name == "Wheel01" ? (isWheel.value = true) : (isWheel.value = false);

        if (animWithMesh) {
          const myTrack = [anim.tracks[animWithMesh.anim_index]];
          if (myTrack.length === 0) {
            console.error(`Invalid track index: ${animWithMesh.anim_index}`);
            return;
          }
          const myClip = new THREE.AnimationClip(name, 1, myTrack);
          if (myClip) {
            if (
              name == "obj77-EQ_조절002" ||
              name == "obj77-EQ_조절3" ||
              name == "obj78-EQ_조절3" ||
              name == "EQ_TEMPO_Control001"
            ) {
            } else {
              if (name == "Start-Start" || name == "Out") {
                name == "Out" ? (isOut.value = !isOut.value) : null;

                action = mixer.clipAction(myClip);
                action.reset();
                action.paused = false; // 애니메이션 일시 정지 해제
                // action.time = 0;
                action.setLoop(THREE.LoopOnce);
                action.setEffectiveTimeScale(2.2);
                action.play(); // 애니메이션 실행
              } else {
                if (run.value) {
                  action = mixer.clipAction(myClip);
                  action.reset();
                  action.paused = false; // 애니메이션 일시 정지 해제
                  action.time = 0;
                  action.setLoop(THREE.LoopOnce);
                  action.setEffectiveTimeScale(2.2);
                  action.play(); // 애니메이션 실행
                }
              }
            }
          } else {
            console.error("No animation clips found");
          }

          if (animWithMesh.action) {
            if (name == "Start-Start") {
              animWithMesh.action();
            } else {
              if (run.value) {
                animWithMesh.action();
              }
            }
          }
        } else {
          console.error("No animation Mesh");
        }
      } else {
        clicked_object = null;
      }
    }
  }

  // 모델링 파일 로드하기
  {
    const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath("https://www.gstatic.com/draco/v1/decoders/");
    loader.setDRACOLoader(dracoLoader);

    loader.load(`modeling/djcontroller.glb`, function (gltf) {
      mixer = new THREE.AnimationMixer(gltf.scene);
      gltfScene.value = gltf.scene;

      // 모델링의 재질을 설정하기
      gltf.scene.traverse((child) => {
        if (child.isMesh) {
          child.material.envMapIntensity = 1;
          child.material.envMap = scene.environment;
          child.material.needsUpdate = true;

          // standard material
          child.material = new THREE.MeshStandardMaterial({
            color: 0xa2a2a2,
            // color: 0xc4c4c4,
            // color: 0xb5b5b5,
            roughness: 0.2,
            // roughness: 0.15,
            metalness: 1.1,
            envMap: scene.environment,
            envMapIntensity: 0.9,
          });

          names.forEach((name) => {
            if (child.name === name) {
              child.material = new THREE.MeshStandardMaterial({
                color: 0x000000,
                roughness: 0.2,
                metalness: 0.5,
                envMap: scene.environment,
                envMapIntensity: 0,
              });
            }
          });

          if (child.name === "Out") {
            outMesh = child;
            child.material = new THREE.MeshStandardMaterial({
              color: 0xffffff,
              emissive: 0x00ff00,
              roughness: 0.1,
              metalness: 0.9,
              emissiveIntensity: 0,
              transparent: true,
              opacity: 0.8,
            });
          }

          if (child.name === "EQ_TEMPO_Control001") {
            child.position.z = -1.187056989649582;
            child.position.y = 0.1648335203890759;
          }

          if (child.name === "Main_Screen") {
            child.material = new THREE.MeshStandardMaterial({
              color: 0x000000,
              roughness: 0.2,
              metalness: 0.5,
              envMap: scene.environment,
              envMapIntensity: 0,
            });
          }

          // if (child.name === "obj12") {
          //   console.log(child);
          //   child.material = new THREE.MeshStandardMaterial({
          //     color: 0xffffff,
          //     roughness: 0.2,
          //     metalness: 0.5,
          //     emissive: 0xffffff,
          //     envMap: scene.environment,
          //     envMapIntensity: 0,
          //   });
          // }
        }
      });

      // gltf.scene 의 중심을 계산해서 gltf.scene 을 0,0,0 으로 이동
      const box = new THREE.Box3().setFromObject(gltf.scene);
      const center = box.getCenter(new THREE.Vector3());
      gltf.scene.position.x += gltf.scene.position.x - center.x;
      gltf.scene.position.y += gltf.scene.position.y - center.y;
      gltf.scene.position.z += gltf.scene.position.z - center.z;

      anim = gltf.animations[0];
      scene.add(gltf.scene);

      setTimeout(() => {
        gltfLoaded.value = true;
      }, 1000);

      // 모델의 특정 메쉬에 텍스처를 매핑합니다.
      const mainScreen = gltf.scene.getObjectByName("Main_Screen");
      const mainScreenBox = new THREE.Box3().setFromObject(mainScreen);
      const mainScreenSize = new THREE.Vector3();
      mainScreenBox.getSize(mainScreenSize); // 크기 (width, height, depth)
      const mainScreenPosition = new THREE.Vector3();
      mainScreenBox.getCenter(mainScreenPosition); // 중심 위치

      const video = document.createElement("video");
      video.src = "loading.mp4"; // 비디오 파일 경로
      video.muted = true; // 자동 재생을 위해 음소거
      video.loop = true; // 비디오 루프 설정
      video.playsInline = true; // iOS에서 인라인 재생

      let videoTexture;

      video.addEventListener("loadedmetadata", () => {
        videoTexture = new THREE.VideoTexture(video);

        const videoWidth = video.videoWidth;
        const videoHeight = video.videoHeight;

        videoTexture.repeat.set(1, 0.56);
        videoTexture.offset.set(0, 0.23);

        //  get image and use texture
        const image = new Image();
        image.src = `screen/song-0${current_song_index.value}.png`;
        // make image three texture
        const texture = new THREE.Texture(image);
        texture.needsUpdate = true;

        // plane geometry 생성 (Main_Screen의 width와 depth를 사용)
        const screen_bottom_geo = new THREE.PlaneGeometry(
          mainScreenSize.x,
          mainScreenSize.z / 2
        );

        const screen_top_geo = new THREE.PlaneGeometry(
          mainScreenSize.x,
          mainScreenSize.z / 2
        );
        screen_top_material = new THREE.MeshStandardMaterial({
          // color: "red",
          map: videoTexture,
          transparent: true,
          opacity: 0,
          roughness: 1.5,
          metalness: 1,
        });
        screen_bottom_material = new THREE.MeshStandardMaterial({
          map: texture,
          transparent: true,
          opacity: 0,
          roughness: 1.5,
          metalness: 1,
        }); // 텍스처 적용

        screen_bottom = new THREE.Mesh(
          screen_bottom_geo,
          screen_bottom_material
        );
        screen_top = new THREE.Mesh(screen_top_geo, screen_top_material);

        // planescreen position의 origin 을 topleft로

        // plane의 위치를 Main_Screen의 윗면에 맞추기
        screen_top.position.set(0.69, 1.6, -5.4);
        screen_bottom.position.set(0.68, 1.2, -3.6);

        // plane을 윗면에 맞추기 위해 회전 (윗면이 평평하게 보이도록)
        screen_top.rotation.x = -Math.PI / 2.39;
        screen_bottom.rotation.x = -Math.PI / 2.34;

        // 씬에 plane 추가
        gltf.scene.add(screen_bottom);
        gltf.scene.add(screen_top);

        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("eqLow")) {
          eqLow.value = parseFloat(urlParams.get("eqLow"));
        }
        if (urlParams.has("eqMid")) {
          eqMid.value = parseFloat(urlParams.get("eqMid"));
        }
        if (urlParams.has("eqHigh")) {
          eqHigh.value = parseFloat(urlParams.get("eqHigh"));
        }
        {
        }
        const minMouseY = -0.6,
          maxMouseY = -0.24,
          minZ = -3.68, // mouseY가 -0.67일 때의 position.z
          maxZ = 1.61, // mouseY가 -0.24일 때의 position.z
          minY = 0.49, // mouseY가 -0.67일 때의 position.y
          maxY = -0.2; // mouseY가 -0.24일 때의 position.
        let rotation = 0;
        if (urlParams.has("current_tempo")) {
          current_tempo.value = parseFloat(urlParams.get("current_tempo"));
          let url_tempo = urlParams.get("current_tempo"); // 예시 값
          let newY =
            ((url_tempo - 0.65) * (0.487 - -0.19)) / (1.35 - 0.65) + -0.19;

          // newY로부터 newZ 계산
          const newZ =
            minZ +
            (maxZ - minZ) * ((newY - maxMouseY) / (minMouseY - maxMouseY));
        }
        if (urlParams.has("isRadioFilter")) {
          isRadioFilter.value = urlParams.get("isRadioFilter") === "true";
        }
        if (urlParams.has("isHiBoost")) {
          isHiBoost.value = urlParams.get("isHiBoost") === "true";
        }
        if (urlParams.has("isLowBoost")) {
          isLowBoost.value = urlParams.get("isLowBoost") === "true";
        }
        if (urlParams.has("tz")) {
          tz.value = parseFloat(urlParams.get("tz"));
        }
        if (urlParams.has("ty")) {
          ty.value = parseFloat(urlParams.get("ty"));
        }

        // GUIS

        // {
        //   const gui = new GUI();

        //   // screen_top position 조정
        //   // videoTexture.repeat.set(1, 0.6);
        //   // videoTexture.offset.set(0, 0.22);
        //   const screenTopFolder = gui.addFolder("Screen top Position");
        //   screenTopFolder
        //     .add(screen_top.position, "x", -10, 10)
        //     .name("Position X")
        //     .onChange(() => {
        //       screen_top.position.x = screen_top.position.x;
        //     });
        //   screenTopFolder
        //     .add(screen_top.position, "y", -10, 10)
        //     .name("Position Y")
        //     .onChange(() => {
        //       screen_top.position.y = screen_top.position.y;
        //     });
        //   screenTopFolder
        //     .add(screen_top.position, "z", -10, 10)
        //     .name("Position Z")
        //     .onChange(() => {
        //       screen_top.position.z = screen_top.position.z;
        //     });
        //   screenTopFolder
        //     .add(videoTexture.repeat, "x", 0, 3)
        //     .step(0.01)
        //     .name("Repeat X")
        //     .onChange(() => {
        //       videoTexture.repeat.x = videoTexture.repeat.x;
        //     });
        //   screenTopFolder
        //     .add(videoTexture.repeat, "y", 0, 3)
        //     .step(0.01)
        //     .name("Repeat Y")
        //     .onChange(() => {
        //       videoTexture.repeat.y = videoTexture.repeat.y;
        //     });
        //   screenTopFolder
        //     .add(videoTexture.offset, "x", -3, 3)
        //     .step(0.01)
        //     .name("offset x")
        //     .onChange(() => {
        //       videoTexture.offset.x = videoTexture.offset.x;
        //     });
        //   screenTopFolder
        //     .add(videoTexture.offset, "y", 0, 3)
        //     .step(0.01)
        //     .name("offset y")
        //     .onChange(() => {
        //       videoTexture.offset.y = videoTexture.offset.y;
        //     });

        //   screenTopFolder.open();
        // }
        // // screen_bottom_pos
        // {
        //   const gui = new GUI();

        //   // screen_bottom의 position 조정
        //   const screenBottomFolder = gui.addFolder("Screen Bottom Position");
        //   screenBottomFolder
        //     .add(screen_bottom.position, "x", -10, 10)
        //     .name("Position X")
        //     .onChange(() => {
        //       screen_bottom.position.x = screen_bottom.position.x;
        //     });
        //   screenBottomFolder
        //     .add(screen_bottom.position, "y", -10, 10)
        //     .name("Position Y")
        //     .onChange(() => {
        //       screen_bottom.position.y = screen_bottom.position.y;
        //     });
        //   screenBottomFolder
        //     .add(screen_bottom.position, "z", -10, 10)
        //     .name("Position Z")
        //     .onChange(() => {
        //       screen_bottom.position.z = screen_bottom.position.z;
        //     });

        //   screenBottomFolder.open();
        // }
      });
      video.load(); // 비디오 로드
      video.play(); // 비디오 재생
      // // 2. Three.js에서 VideoTexture 생성

      // 카메라 gui
      // {
      //   const gui = new GUI();

      //   // 카메라 포지션 및 회전 파라미터 조정
      //   const cameraFolder = gui.addFolder("Camera");
      //   const cameraPositionX = cameraFolder
      //     .add(camera.position, "x", -100, 100)
      //     .name("Position X");
      //   const cameraPositionY = cameraFolder
      //     .add(camera.position, "y", -100, 100)
      //     .name("Position Y");
      //   const cameraPositionZ = cameraFolder
      //     .add(camera.position, "z", -100, 100)
      //     .name("Position Z");
      //   const cameraFov = cameraFolder
      //     .add(camera, "fov", 1, 120)
      //     .name("Field of View")
      //     .onChange(() => camera.updateProjectionMatrix());
      //   const cameraZoom = cameraFolder
      //     .add(camera, "zoom", 0.1, 10)
      //     .name("Zoom")
      //     .onChange(() => camera.updateProjectionMatrix());

      //   // 카메라 회전 조정
      //   const cameraRotationX = cameraFolder
      //     .add(camera.rotation, "x", -Math.PI, Math.PI)
      //     .name("Rotation X");
      //   const cameraRotationY = cameraFolder
      //     .add(camera.rotation, "y", -Math.PI, Math.PI)
      //     .name("Rotation Y");
      //   const cameraRotationZ = cameraFolder
      //     .add(camera.rotation, "z", -Math.PI, Math.PI)
      //     .name("Rotation Z");
      //   cameraFolder.open();

      //   // gltfScene의 회전 조정
      //   const rotationFolder = gui.addFolder("Model Rotation");
      //   rotationFolder
      //     .add(gltf.scene.rotation, "x", -Math.PI, Math.PI)
      //     .name("Rotation X");
      //   rotationFolder
      //     .add(gltf.scene.rotation, "y", -Math.PI, Math.PI)
      //     .name("Rotation Y");
      //   rotationFolder
      //     .add(gltf.scene.rotation, "z", -Math.PI, Math.PI)
      //     .name("Rotation Z");
      //   rotationFolder.open();

      //   // OrbitControls와 dat.GUI 값 동기화
      //   orbitControls.addEventListener("change", () => {
      //     cameraPositionX.setValue(camera.position.x);
      //     cameraPositionY.setValue(camera.position.y);
      //     cameraPositionZ.setValue(camera.position.z);
      //     cameraRotationX.setValue(camera.rotation.x);
      //     cameraRotationY.setValue(camera.rotation.y);
      //     cameraRotationZ.setValue(camera.rotation.z);
      //   });
      // }
      const stst = gltf.scene.getObjectByName("Start-Start");
      const stmat = new THREE.MeshBasicMaterial({
        color: 0xffffff,
        side: THREE.DoubleSide,
      }); // Plane 재질 설정

      if (stst) {
        // 메쉬의 경계 박스 계산
        const boundingBox = new THREE.Box3().setFromObject(stst);

        // 중심점 및 크기 계산
        const center = new THREE.Vector3();
        boundingBox.getCenter(center); // 중심점 계산

        const size = new THREE.Vector3();
        boundingBox.getSize(size); // 크기 계산

        // 같은 크기의 PlaneGeometry 생성
        const planeGeometry = new THREE.PlaneGeometry(
          size.x - 0.6,
          size.y + 0.4
        );

        // Plane 메쉬 생성
        const planeMesh = new THREE.Mesh(planeGeometry, stmat);

        // Plane 메쉬를 'out' 메쉬의 중심점으로 이동
        planeMesh.position.copy(center);

        // Plane의 방향을 동일하게 하려면 'out' 메쉬의 방향(회전)을 복사
        planeMesh.rotation.copy(stst.rotation);
        planeMesh.rotation.x += THREE.MathUtils.degToRad(90); // 15도를 라디안으로 변환

        // Scene에 Plane 메쉬 추가
        gltf.scene.add(planeMesh);
      }

      eqWheelAnimation(animWithMeshes["obj78-EQ_조절3"], 0.165);
      eqWheelAnimation(animWithMeshes["obj77-EQ_조절3"], 0.165);
      eqWheelAnimation(animWithMeshes["obj77-EQ_조절002"], 0.165);

      animate();
    });
  }

  window.addEventListener("resize", () => {
    onWindowResize();
    setIframeratio();
  });
});

let rotationX = 0,
  rotationY = 0,
  rotationZ = 0;
let maxRotation = Math.PI / 80; // 최대 회전 각도, 20도 (라디안)
let rotationSpeed = 0.01; // 회전 속도
let time = 0;

function animate() {
  requestAnimationFrame(animate);
  rotationX = Math.sin(time) * maxRotation;
  rotationY = Math.cos(time) * maxRotation;
  rotationZ = Math.sin(time) * maxRotation;

  gltfScene.value.rotation.x = rotationX; // 모델의 y축 회전 적용
  gltfScene.value.rotation.y = rotationY; // 모델의 y축 회전 적용
  gltfScene.value.rotation.z = rotationZ; // 모델의 y축 회전 적용

  time += rotationSpeed; // 시간 업데이트로 반복적인 회전 구현
  if (mixer) {
    const deltaTime = Math.min(clock.getDelta(), 0.2); // 0.1초(100ms)를 넘지 않도록 제한
    mixer.update(deltaTime);
  }

  if (run.value) {
    if (!scratching.value) {
      current_wheel_rad.value -= 0.01;
    }
  }
  renderer.render(scene, camera);
}

function onWindowResize() {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize(window.innerWidth, window.innerHeight);
}

function setIframeratio() {
  window.innerWidth / window.innerHeight > 1.7
    ? (iframeRatio.value = "landscape")
    : (iframeRatio.value = "portrait");
}
</script>
