<template>
  <PadModel />
</template>

<script>
import PadModel from "./components/PadModel.vue";

export default {
  name: "App",
  components: {
    PadModel,
  },
};
</script>

<style>
@import url("https://use.typekit.net/vkn1crh.css");
body {
  background: black;
}

.p5Canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
</style>
